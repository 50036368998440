import React from "react";
import cx from "classnames";
import style from "./SectionIntroBrief.module.css";

const SectionIntroBrief = ({
  Icon,
  primaryTitle,
  secondaryTitle,
  description,
  className,
}) => {
  return (
    <div className={cx(style.container, className)}>
      <img
        src={Icon}
        alt={primaryTitle + secondaryTitle}
        className={style.mainIcon}
      />
      <div className={style.title}>{primaryTitle}</div>
      <div className={cx(style.title, style.secondaryTitle)}>
        {secondaryTitle}
      </div>
      <div className={style.description}>{description}</div>
    </div>
  );
};

export default SectionIntroBrief;
