import React from "react";
import cx from "classnames";
import style from "./SectionIntroList.module.css";

const SectionIntroList = ({ primaryTitle, secondaryTitle, list }) => {
  return (
    <div className={cx(style.container)}>
      <div className={style.primaryTitle}>{primaryTitle}</div>
      <div className={cx(style.primaryTitle, style.secondaryTitle)}>
        {secondaryTitle}
      </div>
      <div className={style.listWrap}>
        {list.map(({ title, description, icon }) => (
          <div className={style.listItem} key={title}>
            <img src={icon} alt={title} className={style.listIcon} />
            <div>
              <div className={style.itemTitle}>{title}</div>
              <div className={style.itemDescription}>{description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionIntroList;
