import React, { useState } from "react";
import { Button, InteractiveTooltip } from "@my-scoot/component-library-legacy";
import classnames from "classnames";

import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import LightBulbIcon from "assets/vectors/seo/lightbulb.svg";
import style from "./QuickGuideButton.module.css";

const QuickGuideButton = ({
  openGuideModal,
  isTooltipOpen,
  closeTooltip,
  classes = {},
  steps,
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const onNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1);
      return;
    }
    setCurrentStep(0);
    closeTooltip();
  };

  return (
    <div>
      {isTooltipOpen && (
        <>
          <div className={style.overlay} />
          <div className={style.btnOverlay} />
        </>
      )}
      <InteractiveTooltip
        open={isTooltipOpen}
        title={steps[currentStep]}
        customFooter={
          <div className={style.primaryBtnOuterWrap}>
            <Button
              onClick={onNext}
              size="thin"
              buttonWrapperClassName={style.primaryBtnWrap}
            >
              Next
            </Button>
          </div>
        }
        totalSteps={0}
        enterTouchDelay={0}
        leaveTouchDelay={600000}
        disableHoverListener
        disableTouchListener
        titleClassName={classnames("mb-3", style.tooltip)}
        tooltipClasses={{ popperInteractive: style.popperInteractive }}
      >
        <div>
          <Button
            onClick={openGuideModal}
            color="subtlePrimary"
            variant="text"
            className={classes.button}
          >
            <img src={LightBulbIcon} alt="bulb-icon" />
            <span className="ml-2">Quick guide</span>
          </Button>
        </div>
      </InteractiveTooltip>
    </div>
  );
};

export default withComponentLibraryTheme(QuickGuideButton);
