import {
    useListController
} from 'react-admin';
import React from 'react';
import style from './Style.module.css';
import CardWithIcon from '../../modules/CardWithIcon';
import Visibility from '@material-ui/icons/PageviewTwoTone';
import Storage from '@material-ui/icons/ConfirmationNumberTwoTone';
import { is_empty } from '../../../utils/validations';
import dataProvider from "../../../data/dataProvider";

const Statistics = (props) => {
    let { loaded, data } = useListController(props);
    React.useEffect(() => {
        dataProvider.postAnalyticalEvent('creatorPage', 'viewStatistics', { flow: 'creatorPage', funnel: 'creatorPage', ui_component: 'Button', identifier: 'Statistics' })
    }, []);

    return (
        <div className={'parent dm_font'}>
            <div className={` ${style.sticky}`}>
                <div>
                    <div className={`${style.flex_row}`}>
                        <span className={style.bold_black_large_text}>Statistics</span>
                    </div>
                </div>
            </div>
            {loaded && !is_empty(data) && !is_empty(data.stat) ?
                <div>
                    <div className={`row no-gutters ${window.screen.width > 740 ? style.row : ''}`}>
                        <div className={`col-11 col-md-4 ${style.card_margin}`}>
                            <CardWithIcon
                                icon={Visibility}
                                title={"Page Views"}
                                subtitle={data.stat.page_views}
                            />
                        </div>
                        <div className={`col-11 col-md-4 ${style.card_margin}`}>
                            <CardWithIcon
                                icon={Visibility}
                                title={"Page Views in last 7 days"}
                                subtitle={data.stat.page_views}
                            />
                        </div>
                        <div className={`col-11 col-md-4 ${style.card_margin}`}>
                            <CardWithIcon
                                icon={Storage}
                                title={"Total Bookings"}
                                subtitle={data.stat.total_bookings}
                            />
                        </div>
                    </div>
                    <div className={`row no-gutters ${window.screen.width > 740 ? style.row : ''}`}>
                        <div className={`col-11 col-md-4 ${style.card_margin}`}>
                            <CardWithIcon
                                icon={Storage}
                                title={"Active Bookings"}
                                subtitle={data.stat.active_bookings}
                            />
                        </div>
                        <div className={`col-11 col-md-4 ${style.card_margin}`}>
                            <CardWithIcon
                                icon={Storage}
                                title={"Last 7 days bookings"}
                                subtitle={data.stat.total_bookings}
                            />
                        </div>
                    </div>
                    <div className={`row no-gutters`}>
                        <div className={`col-11 col-md-4 ${style.card_margin}`}>
                            <span className={style.disclaimer}>* Your data updates once every hour</span>
                        </div>
                    </div>
                </div>
                : null}
        </div>
    );
}

export default Statistics;